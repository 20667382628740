import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import {
  // company_01,
  // company_02,
  // company_03,
  // company_04,
  // company_05,
  dashboard_card_img,
  // dashboard_card_img1,
  // dashboard_card_img2,
  // dashboard_card_img3,
  // dashboard_card_img4,
} from "../_components/Imagepath";

import { Link } from "react-router-dom";

const Superdashbord = () => {
  const [menu, setMenu] = useState(false);
  const [data, setData] = useState(null);
  let sesssionData = null;

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  useEffect(() => {
    const dataSession = localStorage.getItem("tokenSession");
    const storedData = JSON.parse(dataSession);
    sesssionData = storedData;
    setData(sesssionData);
  }, []);

  return (
    <div>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid pb-0">
            {/* Page Header */}
            <div className="page-header">
              <div className="content-page-header">
                <h5>Dashboard</h5>
              </div>
            </div>
            {/* /Page Header */}
            <div className="super-admin-dashboard ">
              <div className="row">
                <div className="col-xl-5 d-flex">
                  <div className="dash-user-card w-100 bg-blueConstrurama">
                    <h4>
                      <i className="fe fe-sun" />
                      Hola, {data?.user?.name}
                    </h4>

                    <div className="dash-btns">
                      <Link
                        to="/admin/companies"
                        className="btn view-company-btn"
                      >
                        Ver construramas
                      </Link>
                    </div>
                    <div className="dash-img">
                      <img src={dashboard_card_img} alt="" />
                    </div>
                  </div>
                </div>
                {/* <div className="col-xl-7 d-flex p-0">
                  <div className="row dash-company-row w-100 m-0">
                    <div className="col-lg-3 col-sm-6 d-flex">
                      <div className="company-detail-card w-100">
                        <div className="company-icon">
                          <img src={dashboard_card_img1} alt="" />
                        </div>
                        <div className="dash-comapny-info">
                          <h6>Total Companies</h6>
                          <h5>987</h5>
                          <p>
                            <span>
                              14% <i className="fe fe-chevrons-up" />
                            </span>
                            Last month
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 d-flex">
                      <div className="company-detail-card bg-info-light w-100">
                        <div className="company-icon">
                          <img src={dashboard_card_img2} alt="" />
                        </div>
                        <div className="dash-comapny-info">
                          <h6>Active Companies</h6>
                          <h5>154</h5>
                          <p>
                            <span>
                              1% <i className="fe fe-chevrons-up" />
                            </span>
                            Last month
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 d-flex">
                      <div className="company-detail-card bg-pink-light w-100">
                        <div className="company-icon">
                          <img src={dashboard_card_img3} alt="" />
                        </div>
                        <div className="dash-comapny-info">
                          <h6>Inactive Company</h6>
                          <h5>2</h5>
                          <p>
                            <span>
                              2% <i className="fe fe-chevrons-up" />
                            </span>
                            Last month
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 d-flex">
                      <div className="company-detail-card bg-success-light w-100">
                        <div className="company-icon">
                          <img src={dashboard_card_img4} alt="" />
                        </div>
                        <div className="dash-comapny-info">
                          <h6>Total Active Plans</h6>
                          <h5>6</h5>
                          <p>
                            <span>
                              6% <i className="fe fe-chevrons-up" />
                            </span>
                            Last month
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-xl-12 d-flex">
                  <div className="card super-admin-dash-card">
                    <div className="card-header">
                      <div className="row align-center">
                        <div className="col">
                          <h5 className="card-title">
                            Latest Registered Companies
                          </h5>
                        </div>
                        <div className="col-auto">
                          <Link
                            to="/admin/companies"
                            className="btn-right btn btn-sm btn-outline-primary"
                          >
                            View All
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-0">
                      <div className="table-responsive">
                        <table className="table table-stripped table-hover">
                          <tbody>
                            <tr>
                              <td>
                                <h2 className="table-avatar">
                                  <Link
                                    to="/profile"
                                    className="company-avatar avatar-md me-2 companies company-icon"
                                  >
                                    <img
                                      className="avatar-img rounded-circle company"
                                      src={company_01}
                                      alt="Company Image"
                                    />
                                  </Link>
                                  <Link to="/companies">
                                    Hermann Groups{" "}
                                    <span className="plane-type">
                                      Basic (Monthly)
                                    </span>
                                  </Link>
                                </h2>
                              </td>
                              <td>24 Feb 2024</td>
                              <td className="text-end">
                                <Link
                                  to="/companies"
                                  className="view-companies btn"
                                >
                                  View
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h2 className="table-avatar">
                                  <Link
                                    to="/profile"
                                    className="company-avatar avatar-md me-2 companies company-icon"
                                  >
                                    <img
                                      className="avatar-img rounded-circle company"
                                      src={company_02}
                                      alt="Company Image"
                                    />
                                  </Link>
                                  <Link to="/companies">
                                    Skiles LLC{" "}
                                    <span className="plane-type">
                                      Enterprise (Yearly)
                                    </span>
                                  </Link>
                                </h2>
                              </td>
                              <td>23 Feb 2024</td>
                              <td className="text-end">
                                <Link
                                  to="/companies"
                                  className="view-companies btn"
                                >
                                  View
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h2 className="table-avatar">
                                  <Link
                                    to="/profile"
                                    className="company-avatar avatar-md me-2 companies company-icon"
                                  >
                                    <img
                                      className="avatar-img rounded-circle company"
                                      src={company_03}
                                      alt="Company Image"
                                    />
                                  </Link>
                                  <Link to="/companies">
                                    Kerluke Group{" "}
                                    <span className="plane-type">
                                      Advanced (Monthly)
                                    </span>
                                  </Link>
                                </h2>
                              </td>
                              <td>22 Feb 2024</td>
                              <td className="text-end">
                                <Link
                                  to="/companies"
                                  className="view-companies btn"
                                >
                                  View
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h2 className="table-avatar">
                                  <Link
                                    to="/profile"
                                    className="company-avatar avatar-md me-2 companies company-icon"
                                  >
                                    <img
                                      className="avatar-img rounded-circle company"
                                      src={company_04}
                                      alt="Company Image"
                                    />
                                  </Link>
                                  <Link to="/companies">
                                    Schowalter Group{" "}
                                    <span className="plane-type">
                                      Basic (Yearly)
                                    </span>
                                  </Link>
                                </h2>
                              </td>
                              <td>21 Feb 2024</td>
                              <td className="text-end">
                                <Link
                                  to="/companies"
                                  className="view-companies btn"
                                >
                                  View
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <h2 className="table-avatar">
                                  <Link
                                    to="/profile"
                                    className="company-avatar avatar-md me-2 companies company-icon"
                                  >
                                    <img
                                      className="avatar-img rounded-circle company"
                                      src={company_05}
                                      alt="Company Image"
                                    />
                                  </Link>
                                  <Link to="/companies">
                                    Accentric Global{" "}
                                    <span className="plane-type">
                                      Basic (Monthly)
                                    </span>
                                  </Link>
                                </h2>
                              </td>
                              <td>20 Feb 2024</td>
                              <td className="text-end">
                                <Link
                                  to="/companies"
                                  className="view-companies btn"
                                >
                                  View
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Superdashbord;
