import { Construrama2 } from "../../src/_components/Imagepath";
import { Link } from "react-router-dom";
const Identify = () => {
  return (
    <>
      <section className="identify-screen-main">
        <article className="identify-screen-main-section ">
          <div className="section-select">
            <p className="identify-subtitle">Identificate como Administrador</p>
            <Link
              to="/identify/login-admin"
              className="btn btn-lg btn-block w-100 btn-primary w-100"
            >
              Administrador
            </Link>
            <div className="or-container-box">
              <span className="or-line-container" />
              <span className="or-word">o</span>
            </div>
            <p className="identify-subtitle">Identificate como Construrama</p>
            <Link
              to="/identify/login-construrama"
              className="btn btn-lg btn-block w-100 btn-primary w-100"
            >
              Construrama
            </Link>
          </div>
        </article>
        <article className="identify-screen-main-section img">
          <img
            src={Construrama2}
            alt="construrama-img"
            className="img-construrama"
          />
        </article>
      </section>
    </>
  );
};

export default Identify;
