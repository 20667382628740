import React, { useState, useEffect } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Spreadsheet from "react-spreadsheet";
import Loader from "../_components/general/loader";
import { getAllFinishedCatalogs } from "../assets/api/construrama/api_construrama";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { insertItemsInConstrurama } from "../assets/api/admin/admin_api";
import Modalcustom from "../_components/admin/modal";
import ItemsAdded from "../_components/admin/items_added";
const AddItemsToConstrurama = () => {
  const [menu, setMenu] = useState(false);
  const columnLabels = [" Código Pim del producto  "];
  const [rowLabels, setRowLabels] = useState(["1"]);
  const [data, setData] = useState([[{ value: "" }]]);
  const [loader, setLoader] = useState(false);
  const [selectConstrurama, setSelectConstrurama] = useState();
  const [selectOption, setSelectOption] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [resultInsert, setResultInsert] = useState(null);

  let sesssionData = null;

  const dataSession = localStorage.getItem("tokenSession");
  sesssionData = JSON.parse(dataSession);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const addNewRow = () => {
    const newRow = [{ value: "" }];
    setData((prevData) => [...prevData, newRow]);
    const newRowLabel = ` ${rowLabels.length + 1}`;
    setRowLabels((prevLabels) => [...prevLabels, newRowLabel]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      addNewRow();
    }
  };

  const getFinishedCatalogs = async () => {
    setLoader(true);
    try {
      const result = await getAllFinishedCatalogs(
        sesssionData?.access_token,
        0
      );

      setSelectConstrurama(result);
    } catch (e) {
      console.log("ocurrió un error ", e);
    }
    setLoader(false);
  };

  useEffect(() => {
    getFinishedCatalogs();
  }, []);

  const optionsConstrurama = selectConstrurama
    ? selectConstrurama.map((item) => ({
        value: item.id,
        label: item.id + " " + item.name,
        name: "sucursal",
      }))
    : [];

  const handleChangeSelect = (option) => {
    setSelectOption(option);
  };

  //   const notify_sucess = () =>
  //     toast.success("Producto agregado", {
  //       autoClose: 1200,
  //       hideProgressBar: true,
  //     });

  const notify_warning = (msg) =>
    toast.warning(msg, {
      autoClose: 1200,
      hideProgressBar: true,
    });

  const getItemsToSave = async () => {
    if (
      !data.length ||
      data[0][0].value === undefined ||
      data[0][0].value === null ||
      data[0][0].value === ""
    ) {
      notify_warning("La lista no puede estar vacía");
      return;
    }

    if (!selectOption) {
      notify_warning("Debes seleccionar una sucursal");
      return;
    }
    setLoader(true);
    const result = await insertItemsInConstrurama(
      sesssionData?.access_token,
      data,
      selectOption?.value
    );
    setLoader(false);
    setResultInsert(result);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(!openModal);
  };
  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <ToastContainer />
        <div className="page-wrapper">
          <div className="content container-fluid ">
            <div className="flex items-center justify-between w-full m-0 o-0">
              <h5 className="text-2xl font-semibold">
                Añadir productos a catálogos construrama{" "}
              </h5>
            </div>
          </div>
          <div className="min-w-full p-4 flex flex-col">
            <div className="flex flex-row gap-4 items-center">
              <label>Selecciona el construrama </label>
              <Select
                className="border border-gray-300 rounded-lg focus:border-gray-300 z-50 my-2 w-[400px]"
                name="brand"
                placeholder="Selecciona una sucursal"
                options={optionsConstrurama}
                onChange={(choice) => handleChangeSelect(choice)}
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    backgroundColor: "#F9FAFB",
                    border: 0,
                    boxShadow: "none",
                    color: "#D1D5DB;",
                    fontSize: "14px",
                    borderRadius: "8px",

                    width: "100%",
                    padding: "2px",
                  }),
                }}
              />
            </div>
            <div className="h-[500px] border border-solid p-1 border-slate-950	overflow-auto">
              <Spreadsheet
                data={data}
                columnLabels={columnLabels}
                rowLabels={rowLabels}
                onChange={setData}
                onKeyDown={handleKeyDown}
                className="w-auto bg-[#F6F8F9]"
              />
            </div>
            <div className="flex items-center justify-center w-full">
              <button
                onClick={() => getItemsToSave()}
                type="button"
                className="btn btn-primary me-1 mt-2 w-[200px]"
              >
                Agregar productos
              </button>
            </div>
          </div>
        </div>
      </div>
      <Loader show={loader}></Loader>
      <Modalcustom show={openModal} onClose={() => closeModal()}>
        <ItemsAdded resultInsert={resultInsert} />
      </Modalcustom>
    </>
  );
};

export default AddItemsToConstrurama;
