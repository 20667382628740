import PropTypes from "prop-types";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const AuthHandler = ({ children }) => {
  const storedData = JSON.parse(localStorage.getItem("tokenSession"));

  if (storedData?.access_token ) {
    return children;
  } else {
    return <Redirect to="/identify" />;
  }
};
AuthHandler.propTypes = {
  children: PropTypes.object,
};

export default AuthHandler;
