// const URL_BASE = "http://127.0.0.1:8000/api/";
const URL_BASE = "/api/";

/**
 * login with email and password
 *
 *
 * @returns {Map<String, String>}
 */

export const authLogin = async (data) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    email: data.email,
    password: data.password,
  });

  try {
    const send = await fetch(URL_BASE + "users/login", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * logout
 *
 *
 * @returns {Map<String, String>}
 */

export const autLogout = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  myHeaders.append("Authorization", `Bearer ${token}`);


  try {
    const send = await fetch(URL_BASE + "users/logout", {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * create user construrama
 *
 *
 * @returns {Map<String, String>}
 */

export const createConstrurama = async (data, token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  const raw = JSON.stringify({
    name: data.name,
    id: data.rso,
    rfc: data.rfc,
    emails: data.emails,
  });

  try {
    const send = await fetch(URL_BASE + "create-construrama", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};
