import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";

import "../_components/antd.css";
import { Table } from "antd";
// import {  Tooltip } from "antd";
import // onShowSizeChange,
// itemRender,
"../_components/paginationfunction";
// import { filterIcon } from "../_components/Imagepath";
import { getAdmins } from "../assets/api/admin/admin_api";
import Modalcustom from "../_components/admin/modal";
import Adduser from "../_components/admin/adduser";
import Deleteuser from "../_components/admin/deleteUser";

const Customers = () => {
  const [menu, setMenu] = useState(false);
  // const [show, setShow] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [idDelete, setIdDelete] = useState();
  const [dataEdit, setDataEdit] = useState();
  const [data, setData] = useState();

  let sesssionData = null;

  useEffect(() => {
    initScreen();
  }, []);

  const initScreen = () => {
    const dataSession = localStorage.getItem("tokenSession");
    sesssionData = JSON.parse(dataSession);

    getAllAdmins();
  };

  const getAllAdmins = async () => {
    const result = await getAdmins(sesssionData?.access_token);
    setData(result);
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const openAddModal = () => {
    setIsEdit(false);
    setOpenAdd(!openAdd);
    initScreen();
  };

  const openDeleteModal = (id) => {
    setIdDelete(id);
    setOpenDelete(!openDelete);
  };

  const closeDeleteModal = () => {
    initScreen();
    setOpenDelete(!openDelete);
  };

  const openEditModal = (name, id, email) => {
    setOpenAdd(!openAdd);
    setIsEdit(true);
    const data = {
      name: name,
      id: id,
      email: email,
    };
    setDataEdit(data);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      render: (text, record) => (
        <h2 className="table-avatar">
          <p className="font-semibold">{record.name}</p>
        </h2>
      ),
    },

    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Tipo",
      dataIndex: "type",
    },
    {
      title: "Fecha de creacion",
      dataIndex: "created_at",
    },

    {
      render: (record) => (
        <>
          <div className="table-invoice d-flex align-items-center">
            <div className="dropdown dropdown-action">
              <Link
                to="#"
                className=" btn-action-icon "
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-ellipsis-v" />
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <ul>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() =>
                        openEditModal(record.name, record.id, record.email)
                      }
                    >
                      <i className="far fa-edit me-2" />
                      Editar
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      to="#"
                      onClick={() => openDeleteModal(record.id)}
                    >
                      <i className="far fa-trash-alt me-2" />
                      Borrar
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />

        <Sidebar />

        <div className="page-wrapper customers">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="content-page-header">
                <h5>Administradores</h5>
                <div className="list-btn">
                  <ul className="filter-list">
                    <li>
                      <button
                        className="btn btn-primary"
                        onClick={() => openAddModal()}
                      >
                        <i
                          className="fa fa-plus-circle me-2"
                          aria-hidden="true"
                        />
                        Agregar admin
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body">
                    <div className="">
                      {data && data.status !== 404 && (
                        <>
                          <Table
                            columns={columns}
                            dataSource={data}
                            key={data}
                            pagination={false}
                          />
                        </>
                      )}
                      {data && data.status === 404 && (
                        <>
                          <p>Nada que mostrar </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modalcustom show={openAdd} onClose={() => openAddModal()}>
        <Adduser
          cancel={() => openAddModal()}
          editMode={isEdit}
          dataToEdit={dataEdit}
        />
      </Modalcustom>
      <Modalcustom show={openDelete} onClose={() => openDeleteModal()}>
        <Deleteuser onClose={() => closeDeleteModal()} id={idDelete} />
      </Modalcustom>
    </>
  );
};
export default Customers;
