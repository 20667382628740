import React from "react";
import PropTypes from "prop-types";

const ItemsAdded = (props) => {
  const { resultInsert } = props;

  return (
    <>
      {resultInsert?.total > 0 ? (
        <>
          <div className="w-full h-[100px] flex items-center justify-center mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="70"
              height="70"
              viewBox="0 0 50 50"
            >
              <path
                fill="#28a745"
                d="M43.171,10.925L24.085,33.446l-9.667-9.015l1.363-1.463l8.134,7.585L41.861,9.378C37.657,4.844,31.656,2,25,2 C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23C48,19.701,46.194,14.818,43.171,10.925z"
              ></path>
            </svg>
          </div>
        </>
      ) : (
        <>
          <div className="w-full h-[100px] flex items-center justify-center mt-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="70"
              height="70"
              viewBox="0 0 50 50"
            >
              <path
                fill="#dc3545"
                d="M25,2C12.317,2,2,12.317,2,25s10.317,23,23,23s23-10.317,23-23S37.683,2,25,2z M25,44C14.523,44,6,35.477,6,25
    S14.523,6,25,6s19,8.523,19,19S35.477,44,25,44z M34.707,17.293l-1.414,1.414L25,26.586l-8.293-8.293l-1.414,1.414L23.586,28
    l-8.293,8.293l1.414,1.414L25,29.414l8.293,8.293l1.414-1.414L26.414,28l8.293-8.293L34.707,17.293z"
              ></path>
            </svg>
          </div>
        </>
      )}
      <h5 className="text-center">
        Un total de {resultInsert?.total} productos fueron agregados
      </h5>
      <div className="h-[200px] overflow-auto mt-4">
        <table className="table-auto border-collapse border border-gray-200 w-full mt-2 text-xs ">
          <thead>
            <tr>
              <th className="border border-gray-200 px-4 py-2">Codigo PIM</th>
              <th className="border border-gray-200 px-4 py-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {resultInsert.process
              .filter((item) => item?.code === "error")
              .map((item) => (
                <tr key={item.id}>
                  <td className="border border-gray-200 px-4 py-2">
                    {item?.pim_code}
                  </td>
                  <td className="border border-gray-200 px-4 py-2">
                    {item?.message}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
ItemsAdded.propTypes = {
  resultInsert: PropTypes.any,
};
export default ItemsAdded;
