import PropTypes from "prop-types";
import { useEffect } from "react";

const Modalcustom = (props) => {
  const { children, show = false, onClose } = props;

  useEffect(() => {
    document.body.style.overflow = show ? "hidden" : "auto";
  }, [show]);

  const handleClick = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <>
      {show && (
        <>
          <div className="  fixed   top-0 right-0 left-0 z-[999999] justify-center items-center w-full md:inset-0 h-[calc(100%)] max-h-full bg-gray-900 bg-opacity-50 flex ">
            <div className="w-[50%] bg-white p-10 rounded-xl">
              <div className="flex justify-end">
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClick}
                ></button>
              </div>
              {children}
            </div>
          </div>
        </>
      )}
    </>
  );
};

Modalcustom.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func, // Definir prop onClose como función
};
export default Modalcustom;
