// const URL_BASE = "http://127.0.0.1:8000/api/";
const URL_BASE = "/api/";
/**
 * get all construramas
 *
 *
 * @returns {Map<String, String>}
 */

export const getConstruramas = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  try {
    const send = await fetch(URL_BASE + "construramas", {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * create user construrama
 *
 *
 * @returns {Map<String, String>}
 */

export const createConstrurama = async (data, token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    name: data.name,
    id: data.rso,
    rfc: data.rfc,
    emails: data.emails,
  });

  try {
    const send = await fetch(URL_BASE + "create-construrama", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * delete construrama
 *
 *
 * @returns {Map<String, String>}
 */

export const deleteConstrurama = async (data, token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  try {
    const send = await fetch(URL_BASE + "construramas/delete/" + data, {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * edite user construrama
 *
 *
 * @returns {Map<String, String>}
 */

export const updateConstrurama = async (data, id, token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    name: data.name,
    emails: data.emails,
  });

  try {
    const send = await fetch(URL_BASE + "construramas/updates/" + id, {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * add items in construrama list
 *
 *
 * @returns {Map<String, String>}
 */

export const setItemConstrurama = async (user, data) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${user?.access_token}`);

  const id_construrama = user?.user?.email; //the email is equal to the id of the user construrama
  const raw = JSON.stringify({ ...data, id_construrama });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: raw,
  };

  try {
    const send = await fetch(URL_BASE + "construramas/insert", requestOptions);
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * get the items list of each construrama
 *
 *
 * @returns {Map<String, String>}
 */

export const getItemsConstruramasCompare = async (data) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${data?.access_token}`);
  const raw = JSON.stringify({ id_construrama: data?.user?.email });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: raw,
  };

  try {
    const send = await fetch(
      URL_BASE + "construramas/get-list-compare",
      requestOptions
    );
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * remove items in construrama list
 *
 *
 * @returns {Map<String, String>}
 */

export const removeItemConstruramaList = async (user, data) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${user?.access_token}`);

  const raw = JSON.stringify({ index_unq: data });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: raw,
  };

  try {
    const send = await fetch(
      URL_BASE + "construramas/remove-list-item",
      requestOptions
    );
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 *  get all items for each construrama
 *
 *
 * @returns {Map<String, String>}
 */

export const getCatalogPimConstrurama = async (
  token,
  page,
  id_construrama,
  filters
) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    ...filters,
    page: page,
    id_construrama: id_construrama,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: raw,
  };

  try {
    const send = await fetch(
      URL_BASE + "construramas/get-list",
      requestOptions
    );
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * get the info of construramas
 *
 *
 * @returns {Map<String, String>}
 */

export const getInfoConstrurama = async (data, token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    id: data,
  });

  try {
    const send = await fetch(URL_BASE + "construramas/get-info", {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * update the status of the catalog to finished
 *
 *
 * @returns {Map<String, String>}
 */

export const updateStatusCatalogFinished = async (data, status, token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    id: data,
    status: status,
  });

  try {
    const send = await fetch(URL_BASE + "construramas/finish-catalog", {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * get all finished catalogs list
 *
 *
 * @returns {Map<String, String>}
 */

export const getAllFinishedCatalogs = async (token, status) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  const raw = JSON.stringify({
    status: status,
  });

  try {
    const send = await fetch(URL_BASE + "construramas/get-finished-catalogs", {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * download the catalog finished per construrama
 *
 *
 * @returns {Map<String, String>}
 */

export const downloadFinishedCatalog = async (token, index) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    id: index,
  });

  try {
    const send = await fetch(URL_BASE + "construramas/download-catalog", {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    });
    const blob = await send.blob();
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "catálogo-" + index + ".xlsx");

    document.body.appendChild(link);
    link.click();

    window.URL.revokeObjectURL(url);

    return true;
  } catch (error) {
    throw error;
  }
};
