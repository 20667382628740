import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Select from "react-select";
import { getCatalogFilters } from "../../assets/api/admin/admin_api";
// import { useState } from "react";

const Filters = (props) => {
  const { setDataFilterTo } = props;
  const [family, setFamily] = useState([]);
  const [brand, setBrand] = useState([]);
  const [manufacturer, setManufacturer] = useState([]);
  const [tempDataFilter, setTempDataFilter] = useState({
    name: "",
    brand: "",
    manufacturer: "",
    manufacturer_id: "",
    barcode: "",
    family: "",
    is_suggested: "",
    is_cemex: "",
  });

  function handleChange(evt) {
    const { target } = evt;

    const { name, value } = target;

    const newValues = {
      ...tempDataFilter,
      [name]: value,
    };

    setTempDataFilter(newValues);
  }

  function handleChangeSelect(evt) {

    const { name, value } = evt;
    const newValues = {
      ...tempDataFilter,
      [name]: value,
    };

    setTempDataFilter(newValues);
  }

  const handleIsSuggested = (e) => {
    const suggested = e.target.checked ? "si" : "";
    const newValues = { ...tempDataFilter, is_suggested: suggested };
    setTempDataFilter(newValues);
  };

  const handleIsCEMEX = (e) => {
    const cemex = e.target.checked ? "si" : "";
    const newValues = { ...tempDataFilter, is_cemex: cemex };
    setTempDataFilter(newValues);
  };

  const sendFilters = () => {
    setDataFilterTo(tempDataFilter);
  };

  const handleResetFilters = () => {
    const newValues = {
      name: "",
      brand: "",
      manufacturer: "",
      manufacturer_id: "",
      barcode: "",
      family: "",
      is_suggested: "",
      is_cemex: "",
    };

    setTempDataFilter(newValues);
    setDataFilterTo(newValues);
  };

  const sessionData = JSON.parse(
    localStorage.getItem("tokenSession") || "null"
  );

  const getFilter = async () => {
    try {
      const result = await getCatalogFilters(sessionData?.access_token);
      if (result?.code === 200) {
        setFamily(result?.family);
        setManufacturer(result?.manufacturer);
        setBrand(result?.brand);
      }
    } catch (e) {
      console.log(e, "error");
    }
  };

  useEffect(() => {
    getFilter();
  }, []);

  const optionsFamily = family.map((item) => ({
    value: item.family,
    label: item.family,
    name: "family",
  }));

  const optionsBrand = brand.map((item) => ({
    value: item.brand,
    label: item.brand,
    name: "brand",
  }));

  const optionsManufacturer = manufacturer.map((item) => ({
    value: item.manufacturer,
    label: item.manufacturer,
    name: "manufacturer",
  }));
  return (
    <>
      <div className="grid lg:grid-cols-3 xl:grid-cols-4 gap-2 h-[250px] z-50">
        <article className="input-container col-span-1 flex flex-col gap-1">
          <label>Marca </label>
          <Select
            className="border border-gray-300 rounded-lg focus:border-gray-300 z-50"
            name="brand"
            placeholder="Selecciona una marca"
            options={optionsBrand}
            value={{
              value: tempDataFilter?.brand || "default",
              label: tempDataFilter?.brand || "Selecciona una marca",
            }}
            onChange={(choice) => handleChangeSelect(choice)}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: "#F9FAFB",
                border: 0,
                boxShadow: "none",
                color: "#D1D5DB;",
                fontSize: "14px",
                borderRadius: "8px",

                width: "100%",
                padding: "2px",
              }),
            }}
          />
          {/* <input
            type="text"
            name="brand"
            value={tempDataFilter.brand}
            onChange={handleChange}
            placeholder="Filtra por la marca"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          /> */}
        </article>
        <article className="input-container col-span-1 flex flex-col gap-1">
          <label>Nombre</label>
          <input
            type="text"
            name="name"
            value={tempDataFilter.name}
            onChange={handleChange}
            placeholder="Filtra por el nombre"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          />
        </article>
        <article className="input-container col-span-1 flex flex-col gap-1">
          <label>Familia/Categoría</label>

          <Select
            className="border border-gray-300 rounded-lg focus:border-gray-300  z-50" 
            name="family"
            placeholder="Selecciona una familia/categoríalia"
            options={optionsFamily}
            value={{
              value: tempDataFilter?.family || "default",
              label: tempDataFilter?.family || "Selecciona una Familia/Categoría",
            }}
            onChange={(choice) => handleChangeSelect(choice)}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: "#F9FAFB",
                border: 0,
                boxShadow: "none",
                color: "#D1D5DB;",
                fontSize: "14px",
                borderRadius: "8px",

                width: "100%",
                padding: "2px",
              }),
            }}
          />
          {/* <input
            type="text"
            name="family"
            value={tempDataFilter.family}
            onChange={handleChange}
            placeholder="Filtra por la familia"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          /> */}
        </article>
        <article className="input-container col-span-1 flex flex-col gap-1">
          <label>Fabricante</label>
          <Select
            className="border border-gray-300 rounded-lg focus:border-gray-300  z-50"
            name="manufacturer"
            placeholder="Selecciona un fabricante"
            options={optionsManufacturer}
            value={{
              value: tempDataFilter?.manufacturer || "default",
              label: tempDataFilter?.manufacturer || "Selecciona un fabricante",
            }}
            onChange={(choice) => handleChangeSelect(choice)}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: "#F9FAFB",
                border: 0,
                boxShadow: "none",
                color: "#D1D5DB;",
                fontSize: "14px",
                borderRadius: "8px",

                width: "100%",
                padding: "2px",
              }),
            }}
          />
          {/* <input
            type="text"
            name="manufacturer"
            value={tempDataFilter.manufacturer}
            onChange={handleChange}
            placeholder="Filtra por fabricante"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          /> */}
        </article>
        <article className="input-container col-span-1 flex flex-col gap-1">
          <label>No. de fabricante</label>
          <input
            type="text"
            name="manufacturer_id"
            value={tempDataFilter.manufacturer_id}
            onChange={handleChange}
            placeholder="Filtra por fabricante"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          />
        </article>
        <article className="input-container col-span-1 flex flex-col gap-1 ">
          <label>Codigo de barras</label>
          <input
            type="text"
            name="barcode"
            value={tempDataFilter.barcode}
            onChange={handleChange}
            placeholder="Filtra por codigo de barras"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          />
        </article>

        <ul className="ks-cboxtags flex gap-2 items-center col-span-2  pb-3 w-auto">
          <li>
            <input
              id="is_suggested"
              type="checkbox"
              name="is_suggested"
              checked={tempDataFilter?.is_suggested !== ""}
              onChange={handleIsSuggested}
            />
            <label htmlFor="is_suggested">Producto sugerido</label>
          </li>
          <li>
            <input
              id="is_cemex"
              type="checkbox"
              name="is_cemex"
              checked={tempDataFilter?.is_cemex !== ""}
              onChange={handleIsCEMEX}
            />
            <label htmlFor="is_cemex">Producto CEMEX</label>
          </li>
          <button
            onClick={() => sendFilters()}
            type="button"
            className="btn btn-primary  w-[200px]"
          >
            Buscar
          </button>
          <button
            onClick={() => handleResetFilters()}
            type="button"
            className="btn btn-primary w-[300px] px-0 2xl:w-[200px]"
          >
            Borrar filtros
          </button>
        </ul>
    
      </div>
    </>
  );
};

Filters.propTypes = {
  dataToFilter: PropTypes.any,
  dataFilterTo: PropTypes.any,
  setDataFilterTo: PropTypes.any,
};

export default Filters;
