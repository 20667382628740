import React, { useEffect, useState } from "react";
import Header from "../layouts/Header.jsx";
import Sidebar from "../layouts/Sidebar.jsx";

import { Table } from "antd";
// import { Tooltip } from "antd";
import // itemRender,
// onShowSizeChange,
"../_components/paginationfunction.jsx";
import { Link } from "react-router-dom";
import DeleteModal from "../common/modal/DeleteModal.jsx";
// import { filterIcon } from "../_components/Imagepath.jsx";
import Filter from "../main/superadmin/filter.jsx";
import UpdatePackage from "../main/superadmin/updatepackage.jsx";
import CompanyDetails from "../main/superadmin/companydetails.jsx";
import Editcompany from "../main/superadmin/editcompany.jsx";

import Addcompany from "../_components/admin/addcompany.jsx";
import Modalcustom from "../_components/admin/modal.jsx";
import { getConstruramas } from "../assets/api/construrama/api_construrama.js";
import Deleteconstrurama from "../_components/admin/deleteConstrurama.jsx";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

const Companies = () => {
  let history = useHistory();

  const [data, setData] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [idDelete, setIdDelete] = useState();
  const [isEdit, setIsEdit] = useState(false);

  let sesssionData = null;

  useEffect(() => {
    initScreen();
  }, []);

  const initScreen = () => {
    const dataSession = localStorage.getItem("tokenSession");
    sesssionData = JSON.parse(dataSession);
    getAllConstruramas();
  };

  const getAllConstruramas = async () => {
    const result = await getConstruramas(sesssionData?.access_token);
    setData(result);
  };

  const [menu, setMenu] = useState(false);
  const [show, setShow] = useState(false);
  const [dataEdit, setDataEdit] = useState();
  const [openAdd, setOpenAdd] = useState(false);

  const seeCatalog = (id,status) => {
    history.push("/admin/see-catalog-finished", { isFinished: status, rso_id: id });
  };
  
  const openAddModal = () => {
    setOpenAdd(!openAdd);
    setIsEdit(false);
    setDataEdit("");
    initScreen();
  };

  const openDeleteModal = (id) => {
    setIdDelete(id);
    setOpenDelete(!openDelete);
  };

  const closeDeleteModal = () => {
    initScreen();
    setOpenDelete(!openDelete);
  };

  const openEditModal = (name, id, emails) => {
    setOpenAdd(!openAdd);
    setIsEdit(true);
    const data = {
      name: name,
      id: id,
      emails: emails,
    };

    setDataEdit(data);
  };
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const columns = [
    {
      title: "RSO ID",
      dataIndex: "id",
      sorter: (a, b) => a.id?.length - b.id?.length,
    },
    {
      title: "RFC",
      dataIndex: "rfc",
      sorter: (a, b) => a.rfc?.length - b.rfc?.length,
    },
    {
      title: "Nombre",
      dataIndex: "name",
      render: (index, record) => (
        <h2 className="table-avatar">
        <button
        className="hover:text-violet-500 font-semibold"
          onClick={() => seeCatalog(record?.id, record?.status)}>
          {record?.name}
          </button>
        </h2>
      ),
      sorter: (a, b) => a.name?.length - b.name?.length,
    },

    {
      title: "Emails",
      dataIndex: "emails",
      render: (text) => (
        <a href="#" data-bs-toggle="modal ">
          <span className="badge bg-purple w-[170px] truncate">{text}</span>
        </a>
      ),
      sorter: (a, b) => a.emails?.length - b.emails?.length,
    },
    {
      title: "Fecha de creacion",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at?.length - b.created_at?.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <div>
          {text === 0 && (
            <span className="badge bg-danger-light ">
              <i className="fe fe-x me-1"></i>
              En proceso
            </span>
          )}
          {text === 1 && (
            <span className="badge  bg-success-light">
              <i className="fe fe-check me-1"></i>
              Finalizado
            </span>
          )}
        </div>
      ),
      sorter: (a, b) => a.status?.length - b.status?.length,
    },
    {
      render: (text, record) => (
        <div className="table-invoice d-flex align-items-center">
          <div className="dropdown dropdown-action">
            <Link
              to="#"
              className=" btn-action-icon "
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v" />
            </Link>
            <div className="dropdown-menu dropdown-menu-end">
              <ul>
                {/* <li>
                  <button className="dropdown-item">
                    <i className="far fa-eye me-2"></i>Ver
                  </button>
                </li> */}
                <li>
                  <button
                    className="dropdown-item"
                    onClick={() =>
                      openEditModal(record?.name, record?.id, record?.emails)
                    }
                  >
                    <i className="fe fe-edit me-2" />
                    Editar
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    to="#"
                    onClick={() => openDeleteModal(record?.id)}
                  >
                    <i className="far fa-trash-alt me-2" />
                    Borrar
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ),
      sorter: (a, b) => a.Action?.length - b.Action?.length,
    },
  ];
  return (
    <div>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Page Header */}
            <div className="page-header">
              <div className="content-page-header">
                <h5>Construramas</h5>
                <div className="page-content">
                  <div className="list-btn">
                    <ul className="filter-list">
                      <li>
                        <button
                          className="btn-filters"
                          onClick={() => initScreen()}
                          title="Refresh"
                        >
                          <span>
                            <i className="fe fe-refresh-ccw" />
                          </span>
                        </button>
                      </li>

                      {/* <li>
                        <Link
                          to="#"
                          className="btn btn-filters w-auto popup-toggle"
                          onClick={() => setShow(!show)}
                        >
                          <span className="me-2">
                            <Tooltip title="Filter">
                              <img src={filterIcon} alt="filter" />
                            </Tooltip>
                          </span>
                          Filter
                        </Link>
                      </li> */}
                      {/* <li>
                        <div
                          className="dropdown dropdown-action"
                          data-bs-toggle="tooltip"
                          data-bs-placement="bottom"
                          title="Download"
                        >
                          <a
                            href="#"
                            className="btn btn-filters"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <span className="me-2">
                              <i className="fe fe-download" />
                            </span>
                            Export
                          </a>
                          <div className="dropdown-menu dropdown-menu-end">
                            <ul className="d-block">
                              <li>
                                <a
                                  className="d-flex align-items-center download-item"
                                  href="#"
                                  download=""
                                >
                                  <i className="far fa-file-pdf me-2" />
                                  Export as PDF
                                </a>
                              </li>
                              <li>
                                <a
                                  className="d-flex align-items-center download-item"
                                  href="#"
                                  download=""
                                >
                                  <i className="far fa-file-text me-2" />
                                  Export as Excel
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li> */}

                      <li>
                        <a
                          className="btn btn-primary"
                          onClick={() => openAddModal()}
                        >
                          <i
                            className="fa fa-plus-circle me-2"
                            aria-hidden="true"
                          />
                          Agrega un construrama
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {/* /Page Header */}
            <div className="row">
              <div className="col-sm-12">
                <div className="card-table">
                  <div className="card-body">
                    <div className="">
                      {data && data.status !== 404 && (
                        <>
                          <Table
                            pagination={false}
                            columns={columns}
                            dataSource={data}
                            key={data}
                          />
                        </>
                      )}
                      {data && data.status === 404 && (
                        <>
                          <p>Nada que mostrar </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal />
      <Filter setShow={setShow} show={show} />
      <Modalcustom show={openAdd} onClose={() => openAddModal()}>
        <Addcompany
          cancel={() => openAddModal()}
          editMode={isEdit}
          dataToEdit={dataEdit}
        />
      </Modalcustom>
      <Modalcustom show={openDelete} onClose={() => closeDeleteModal()}>
        <Deleteconstrurama onClose={() => closeDeleteModal()} id={idDelete} />
      </Modalcustom>
      <UpdatePackage />
      <CompanyDetails />
      <Editcompany />
    </div>
  );
};

export default Companies;
