import PropTypes from "prop-types";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const AutHandlerConstrurama = ({ children }) => {
  const storedData = JSON.parse(localStorage.getItem("tokenSession"));

  if (storedData?.access_token && storedData?.user?.type === "construrama") {
    return children;
  } else {
    return <Redirect to="/identify" />;
  }
};
AutHandlerConstrurama.propTypes = {
  children: PropTypes.object,
};

export default AutHandlerConstrurama;
