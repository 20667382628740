import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import TablePimConstrurama from "../_components/construrama/tablePimConstrurama";
import { getInfoConstrurama } from "../assets/api/construrama/api_construrama";
import Modalcustom from "../_components/admin/modal";
import ConfirmCatalog from "../_components/construrama/confirmCatalog";
import Loader from "../_components/general/loader";

const CatalogConstrurama = () => {
  const [menu, setMenu] = useState(false);
  const [dataConstruramaInfo, setDataConstruramaInfo] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loder, setLoader] = useState(false);
  const [isFinished, setIsFinished] = useState(false);

  const dataConstrurama = JSON.parse(
    localStorage.getItem("tokenSession") || "null"
  );

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const closeModal = () => {
    setOpenModal(!openModal);
    getConstrurama();
  };

  const getConstrurama = async () => {
    setLoader(true);
    try {
      const result = await getInfoConstrurama(
        dataConstrurama?.user?.email,
        dataConstrurama?.access_token
      );
      setDataConstruramaInfo(result);
      setIsFinished(result?.data?.status);
    } catch (e) {
      console.log("error ", e);
    }
    setLoader(false);
  };

  useEffect(() => {
    getConstrurama();
  }, []);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid ">
            <div className="flex items-center justify-between w-full m-0 o-0">
              <h5 className="text-2xl font-semibold">Mi Catálogo</h5>
              {dataConstruramaInfo?.data?.status === 0 && (
                <button
                  onClick={() => closeModal()}
                  type="button"
                  className="btn btn-primary me-1 mt-2 w-[200px]"
                >
                  Finalizar Catálogo
                </button>
              )}
              {dataConstruramaInfo?.data?.status === 1 && (
                <button
                  type="button"
                  disabled
                  className="btn border-1 border-blue-600 me-1 mt-2 w-[200px]"
                >
                  Catálogo finalizado.
                </button>
              )}
            </div>
          </div>
          <div className="min-w-full">
            <TablePimConstrurama isFinished={isFinished}></TablePimConstrurama>
          </div>
        </div>
      </div>
      <Modalcustom show={openModal} onClose={() => closeModal()}>
        <ConfirmCatalog onClose={() => closeModal()} />
      </Modalcustom>
      <Loader show={loder}></Loader>
    </>
  );
};

export default CatalogConstrurama;
