/* eslint-disable */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Construrama2 } from "../../src/_components/Imagepath";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailrgx } from "../assets/constant";
import { authLogin } from "../assets/api/logins/api_logins";
import { useHistory } from "react-router-dom";

const schema = yup.object({
  rso: yup
    .string()
    .required("Debes ingresar el RSO")
    .trim(),
  rfc: yup
    .string()
    .min(12, "el RFC ingresado no es correcto")
    .max(13, "el RFC ingresado tiene mas de 13 digitos")
    .required("Debes ingresar el RFC")
    .trim(),
});

const LoginConstrurama = (props) => {
  const [errorLogin, setErrorLogin] = useState();
  const [errorUnable, setErrorUnable] = useState();
  const history = useHistory();
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rso: "",
      rfc: " ",
    },
  });

  const login = async (data) => {
    try {
      const result = await authLogin(data);
      if (result?.access_token) {
        localStorage.setItem("tokenSession", JSON.stringify(result));
        history.push("/construrama/catalog");
      } else if (result?.error) {
        console.log("hubo error", result?.error);
        setErrorLogin(result?.error);
      }
    } catch (e) {
      setErrorUnable("Hubo un error inesperado, intenta mas tarde");
    }
  };

  const onSubmit = (data) => {
    const newData = {
      email: data.rso,
      password: data.rfc,
    };
    if (data.rso != null && data.rfc != null) {
      clearErrors("password");
      login(newData);
      setErrorLogin("");
      clearErrors();
    }
  };

  return (
    <>
      <section className="identify-screen-main">
        <article className="identify-screen-main-section ">
          <div className="main-wrapper login-body">
            <div className="login-wrapper">
              <div className="container">
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                      <p className="account-subtitle">Acceso Construrama</p>
                      <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="input-block mb-3 input_text login-eye">
                            <label className="form-control-label">RSO ID</label>
                            <Controller
                              name="rso"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <input
                                  className={`form-control  ${
                                    errors?.rso ? "error-input" : ""
                                  }`}
                                  type="text"
                                  onChange={onChange}
                                />
                              )}
                            />
                            <div className="input-block mb-3"></div>
                            <p>{errors?.rso?.message}</p>
                          </div>
                          <div className="input-block mb-3 input_text login-eye">
                            <label className="form-control-label">RFC</label>
                            <Controller
                              name="rfc"
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <div className="pass-group">
                                  <input
                                    className={`form-control  ${
                                      errors?.rfc ? "error-input" : ""
                                    }`}
                                    type="text"
                                    onChange={onChange}
                                  />
                                </div>
                              )}
                            />
                          </div>

                          <small>{errors?.rfc?.message}</small>
                          <small>{errorLogin}</small>
                          <small>{errorUnable}</small>
                          <div className="input-block mb-3"></div>
                          <button
                            className="btn btn-lg btn-block w-100 btn-primary w-100"
                            type="submit"
                          >
                            Enviar
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <article className="identify-screen-main-section img">
          <img
            src={Construrama2}
            alt="construrama-img"
            className="img-construrama"
          />
        </article>
      </section>
    </>
  );
};

export default LoginConstrurama;
