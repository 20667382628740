import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import TablePimConstrurama from "../_components/construrama/tablePimConstrurama";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

import { useHistory } from "react-router-dom/cjs/react-router-dom";
const ConstruramaCatalogAdmin = () => {
  let location = useLocation();
  const { rso_id } = location.state || {};
  const [menu, setMenu] = useState(false);
  let history = useHistory();
  
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  useEffect(() => {
    
  }, []);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid ">
            <div className="flex items-center justify-between w-full m-0 o-0">
              <div className="flex items-center justify-between gap-[20px]">
                <div className="list-btn">
                  <ul className="filter-list">
                    <button
                      onClick={() =>
                        history.push("/admin/catalogs-finished")
                      }
                      className="btn-filters"
                      title="ir atrás"
                    >
                      <span>
                        <i className="fe fe-arrow-left" />
                      </span>
                    </button>
                  </ul>
                </div>
                <h5 className="text-2xl font-semibold">Catálogo {rso_id}</h5>
              </div>
            </div>
          </div>
          <div className="min-w-full">
            <TablePimConstrurama rso_id={rso_id}></TablePimConstrurama>
          </div>
        </div>
      </div>
    </>
  );
};
// ConstruramaCatalogAdmin.propTypes = {
//   rso_id: PropTypes.any.isRequired,
// };
export default ConstruramaCatalogAdmin;
