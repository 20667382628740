import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Sidebar from "../layouts/Sidebar";
import Loader from "../_components/general/loader";
import Modalcustom from "../_components/admin/modal";
import { getNewURLsigned } from "../assets/api/admin/admin_api";
import UploadFile from "./uploadFile";
import TablePim from "../_components/admin/tablePim";

const CatalogPage = () => {
  const [menu, setMenu] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [url, setUrl] = useState(false);
  const [nameFile, setNameFile] = useState();
  const [isAdmin, setIsAdmin] = useState(false);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const loadNewUrl = async () => {
    setOpenLoader(!openLoader);
    const sessionData = JSON.parse(
      localStorage.getItem("tokenSession") || "null"
    );

    const response = await getNewURLsigned(sessionData?.access_token);

    if (response.url) {
      setNameFile(response?.fileName);
      setOpenLoader(false);
      setOpenModal(!openModal);
      setUrl(response?.url);
    } else {
      setOpenLoader(true);
    }
  };

  const closeModal = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    const sessionData = JSON.parse(
      localStorage.getItem("tokenSession") || "null"
    );

    setIsAdmin(sessionData?.user?.type === "construrama" ? false : true);
  }, []);

  return (
    <>
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />
        <Sidebar />

        <div className="page-wrapper">
          <div className="content container-fluid ">
            <div className="flex items-center justify-between w-full m-0 o-0">
              <h5 className="text-2xl font-semibold">Catálogo </h5>

              {isAdmin && (
                <button
                  onClick={() => loadNewUrl()}
                  type="button"
                  className="btn btn-primary me-1 mt-2 w-[200px]"
                >
                  Subir catálogo
                </button>
              )}
            </div>
          </div>
          <div className="min-w-full">
            <TablePim />
          </div>
        </div>
      </div>

      <Loader show={openLoader}></Loader>
      <Modalcustom show={openModal} onClose={() => closeModal()}>
        <UploadFile url={url} fileName={nameFile} cancel={() => closeModal()} />
      </Modalcustom>
    </>
  );
};

export default CatalogPage;
