// const URL_BASE = "http://127.0.0.1:8000/api/";
const URL_BASE = "/api/";
// console.log(import.meta.env.REACT_APP_API_BASE_URL , 'esto es la env')

/**
 * get all admin
 *
 *
 * @returns {Map<String, String>}s
 */

export const getAdmins = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);
  try {
    const send = await fetch(URL_BASE + "users", {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * create user admin
 *
 *
 * @returns {Map<String, String>}
 */

export const createAdmin = async (data, token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    name: data.name,
    password: data.password,
    email: data.email,
  });

  try {
    const send = await fetch(URL_BASE + "create-user", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * delete an admin
 *
 *
 * @returns {Map<String, String>}
 */

export const deleteAdmin = async (data, token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  try {
    const send = await fetch(URL_BASE + "users/delete/" + data, {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * edite user admin
 *
 *
 * @returns {Map<String, String>}
 */

export const updateAdmin = async (data, id, token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    name: data.name,
    email: data.email,
  });

  try {
    const send = await fetch(URL_BASE + "users/updates/" + id, {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * generate a new url signed for upload files to the bucket
 *
 *
 * @returns {Map<String, String>}
 */

export const getNewURLsigned = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  try {
    const send = await fetch(URL_BASE + "get-url-signed", {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * send the file to the bucket
 *
 *
 * @returns {Map<String, String>}
 */

export const sendFile = async (url, file) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/octet-stream");
  myHeaders.append("Access-Control-Allow-Origin", "*");

  try {
    const send = await fetch(url, {
      method: "PUT",
      headers: myHeaders,
      body: file,
      redirect: "follow",
    });

    const res = await send;
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * send the file to the bucket
 *
 *
 * @returns {Map<String, String>}
 */

export const sendMessage = async (token, fileName) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    name: fileName,
  });

  console.log(raw);
  try {
    const send = await fetch(URL_BASE + "send-message-file-signed", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    });

    const res = await send;
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * get all admin
 *
 *
 * @returns {Map<String, String>}
 */

// export const getCatalogPim = async (token, page, filters) => {
//   const myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");
//   myHeaders.append("Authorization", `Bearer ${token}`);
//   try {
//     const params = new URLSearchParams({
//       name: filters?.name,
//       brand: filters?.brand,
//       manufacturer: filters?.manufacturer,
//       manufacturer_id: filters?.manufacturer_id,
//       barcode: filters?.barcode,
//       family: filters?.family,
//       is_suggested: filters?.is_suggested,
//       is_cemex: filters?.is_cemex,
//     });

//     console.log(params, "parametro");

//     const send = await fetch(
//       URL_BASE + "get-pims-items?page=" + page + `?${params}`,
//       {
//         method: "GET",
//         headers: myHeaders,
//         redirect: "follow",
//       }
//     );
//     const res = await send.json();
//     return res;
//   } catch (error) {
//     throw error;
//   }
// };

export const getCatalogPim = async (token, page, filters) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({ ...filters, page: page });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    redirect: "follow",
    body: raw,
  };

  try {
    const send = await fetch(URL_BASE + "get-pims-items", requestOptions);
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

export const getCatalogFilters = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  try {
    const send = await fetch(
      URL_BASE + "get-pims-filters-distinct",
      requestOptions
    );
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * reset the construramas catalog
 *
 *
 * @returns {Map<String, String>}
 */

export const resetCatalog = async (data, token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    id: data,
  });

  try {
    const send = await fetch(URL_BASE + "construramas/reset-catalog", {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

/**
 * get the general configs
 *
 *
 * @returns {Map<String, String>}
 */

export const getConfigs = async (token) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  try {
    const send = await fetch(URL_BASE + "configs", {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    });
    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};

export const insertItemsInConstrurama = async (token, data, id_sucursal) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify({
    id: id_sucursal,
    data_to_insert: data,
  });

  try {
    const send = await fetch(URL_BASE + "construramas/insert-from-admin", {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    });

    const res = await send.json();
    return res;
  } catch (error) {
    throw error;
  }
};
