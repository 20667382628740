import { Table } from "antd";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Filters from "../general/filters";
import Loader from "../general/loader";
import {
  getCatalogPimConstrurama,
  removeItemConstruramaList,
} from "../../assets/api/construrama/api_construrama";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const TablePimConstrurama = (props) => {
  const location = useLocation();
  const { isFinished, rso_id } = location.state || props;

  // let page = 1;
  const [page, setPage] = useState(1);
  const [dataPim, setData] = useState([]);
  const { from, to, total, current_page, last_page } = dataPim;
  const [auxData, setAuxData] = useState(false);
  const [loder, setLoader] = useState(false);
  const [openLoader, setOpenLoader] = useState(false);

  // const maxLength = 60;
  const [dataFilterTo, setDataFilterTo] = useState({
    name: "",
    brand: "",
    manufacturer: "",
    manufacturer_id: "",
    barcode: "",
    family: "",
    is_suggested: "",
    is_cemex: "",
  });

  const sessionData = JSON.parse(
    localStorage.getItem("tokenSession") || "null"
  );

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;
    const totalPages = last_page;

    if (totalPages <= maxVisiblePages + 1) {
      // Si hay 6 o menos páginas, mostrarlas todas
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (page <= 3) {
        // Si la página actual es 1, 2 o 3, muestra las primeras cinco y la última página con puntos
        for (let i = 1; i <= 5; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      } else if (page >= totalPages - 2) {
        // Si la página actual está entre las tres últimas, muestra la primera página, puntos y las últimas cinco
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (let i = totalPages - 4; i <= totalPages; i++) {
          pageNumbers.push(i);
        }
      } else {
        // Si está en alguna otra página, muestra la primera, puntos, las páginas alrededor de la actual, más puntos y la última
        pageNumbers.push(1);
        pageNumbers.push("...");
        for (let i = page - 2; i <= page + 2; i++) {
          pageNumbers.push(i);
        }
        pageNumbers.push("...");
        pageNumbers.push(totalPages);
      }
    }

    return pageNumbers;
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber !== page) {
      setPage(pageNumber);
    }
  };

  const columns = [
    {
      title: "Producto",
      dataIndex: "img_url",
      fixed: "left",
      width: 90,
      render: (text, data) => (
        <div className="w-[70px] h-[70px]  relative">
          <div className=" bottom-0 absolute">
            <img className="w-[100%] max-h-[72px]" src={text} alt="img-product"></img>
          </div>
          {data?.exist_pim === 0 && (
            <div className="bg-red-600 w-[30px] h-[30px] rounded-full top-[-5px] right-[-10px] absolute flex items-center justify-center">
              <span className="mb-[5px]">
                <i className="fe fe-alert-triangle text-white text-lg " />
              </span>
            </div>
          )}
        </div>
      ),
    },
    {
      title: "Nombre",
      dataIndex: "name",
      width: 500,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (name) => (
        <div
          style={{
            width: "500px",
            fontSize: "18px",
            whiteSpace: "normal", // Asegura que el texto haga saltos de línea
            wordWrap: "break-word",
          }}
        >
          {name}
          {/* {name.length > maxLength
          ? name.substring(0, maxLength) + "..."
          : name} */}
        </div>
      ),
    },

    {
      title: "Unidad de Venta",
      dataIndex: "units",
      width: 200,
      render: (units) => <p className="text-[18px]">{units}</p>,
    },
    {
      title: "Marca",
      dataIndex: "brand",
      width: 200,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (brand) => <p className="text-[18px]">{brand}</p>,
    },
    {
      title: "Fabricante",
      dataIndex: "manufacturer",
      width: 200,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend", "ascend"],
      render: (manufacturer) => <p className="text-[18px]">{manufacturer}</p>,
    },
    {
      title: "Numero de Fabricante",
      dataIndex: "manufacturer_id",
      width: 200,
      render: (manufacturer_id) => (
        <p className="text-[18px]">{manufacturer_id}</p>
      ),
    },
    {
      title: " Codigo de barras",
      dataIndex: "barcode",
      width: 200,
      render: (barcode) => <p className="text-[18px]">{barcode}</p>,
    },
    // {

    // },
  ];
  //
  if (isFinished !== 1 && rso_id === undefined) {
    columns.push({
      title: "Accion",
      dataIndex: "index_unq",
      width: 100,
      fixed: "right",
      render: (index) => (
        <div className="w-[100px] flex items-center justify-between gap-1">
          <button
            onClick={() => deleteItem(index)}
            className="bg-[#ff3500]  text-white p-2.5 w-[200px] rounded-md ml-2 "
          >
            Eliminar
          </button>
        </div>
      ),
    });
  }

  const notifyDelete = () =>
    toast.success("Producto eliminado", {
      autoClose: 1200,
      hideProgressBar: true,
    });



  const getAllItems = async () => {
    const rsoId = rso_id ?? sessionData?.user?.email;

    setLoader(true);
    try {
      const response = await getCatalogPimConstrurama(
        sessionData?.access_token,
        page,
        rsoId,
        dataFilterTo
      );
      setLoader(false);
      setData(response);
    } catch (e) {
      setLoader(false);
    }
  };

  // this will work only for construrama users, its the function to insert in the databae
  // the data selected

  const deleteItem = async (index) => {
    setAuxData(true);
    try {
      const response = await removeItemConstruramaList(sessionData, index);

      if (response?.status === 200) {
        setOpenLoader(false);
        notifyDelete();
      } else {
        toast.warning("Este producto ya no existe en tu lista", {
          autoClose: 1200,
          hideProgressBar: true,
        });
        setOpenLoader(false);
      }
    } catch (e) {
      setOpenLoader(false);
      toast.error("Error al eliminar", {
        autoClose: 1200,
        hideProgressBar: true,
      });
    }
    setAuxData(false);
  };

  useEffect(() => {
    getAllItems();
  }, [dataFilterTo, page, auxData]);

  return (
    <>
      <Loader show={loder} />
      <div className=" h-auto flex flex-col gap-[20px] max-w-full p-[20px]">
        <section className="filters-table w-full h-auto ">
          <Filters
            dataFilterTo={dataFilterTo}
            setDataFilterTo={setDataFilterTo}
          />
          <ToastContainer />
        </section>
        <section className="table-pim max-w-full  overflow-x-auto pb-[20px]">
          <Table
            columns={columns}
            dataSource={dataPim?.data?.map((item) => ({
              ...item,
              key: item?.pim_code,
            }))}
            pagination={false}
            rowKey="key"
            className="border border-slate-100 rounded-lg"
            scroll={{
              x: "calc(100% + 50%)",
            }}
            locale={{ emptyText: "Sin productos todavia" }}
          />

          <section className="w-full h-[100px] flex items-center justify-between px-5">
            <p className="text-[14px] text-gray-600">
              Mostrando del {from} al {to} de {total} resultados
            </p>
            <article className="flex items-center gap-3">
              <button
                onClick={() => handlePageChange(1)}
                className={`text-[#1C3661] p-2.5 w-[100px] rounded-md ${
                  page === 1
                    ? "cursor-not-allowed opacity-50"
                    : "hover:text-orange-500"
                }`}
                disabled={page === 1}
              >
                Primero
              </button>
              <button
                onClick={() => handlePageChange(current_page - 1)}
                className={`bg-[#1C3661] text-white p-2.5 w-[100px] rounded-md ${
                  page === 1 ? "cursor-not-allowed opacity-50" : ""
                }`}
                disabled={page === 1}
              >
                Anterior
              </button>
              {generatePageNumbers().map((pageNumber, index) => (
                <button
                  key={
                    typeof pageNumber === "number"
                      ? `page-${pageNumber}`
                      : `ellipsis-${index}`
                  }
                  onClick={() => handlePageChange(pageNumber)}
                  className={`px-[10px] py-2 rounded-md ${
                    page === pageNumber
                      ? "bg-[#1C3661] text-white"
                      : "bg-gray-200 text-gray-600"
                  }`}
                  disabled={typeof pageNumber !== "number"} 
                >
                  {pageNumber}
                </button>
              ))}

              <button
                onClick={() => handlePageChange(current_page + 1)}
                className={`bg-[#1C3661] text-white p-2.5 w-[100px] rounded-md ${
                  page === last_page ? "cursor-not-allowed opacity-50" : ""
                }`}
                disabled={page === last_page}
              >
                Siguiente
              </button>
              <button
                onClick={() => handlePageChange(last_page)}
                className={`text-[#1C3661] p-2.5 w-[100px] rounded-md ${
                  page === last_page
                    ? "cursor-not-allowed opacity-50"
                    : "hover:text-orange-500"
                }`}
                disabled={page === last_page}
              >
                Última
              </button>
            </article>
          </section>
        </section>
      </div>
      <Loader show={openLoader}></Loader>
    </>
  );
};

TablePimConstrurama.propTypes = {
  isFinished: PropTypes.number,
  rso_id: PropTypes.any.isRequired,
};

export default TablePimConstrurama;
