import PropTypes from "prop-types";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { useForm, Controller } from "react-hook-form";
import { createConstrurama } from "../../assets/api/logins/api_logins";
import { useEffect, useState } from "react";
import { updateConstrurama } from "../../assets/api/construrama/api_construrama";
// import { useEffect } from "react";

const schema = yup.object({
  name: yup
    .string()
    .required("Ingresa el nombre de la sucursal")
    .trim(),
  emails: yup
    .string()
    .required("Ingresa emails")
    .trim(),
});

const rsoSchema = yup.object({
  rso: yup
    .string()
    .required("Ingresa el RSO ID")
    .trim(),
});

const rfcSchema = yup.object({
  rfc: yup
    .string()
    .required("Ingresa el RFC")
    .min(12, "El RFC ingresado no es correcto")
    .max(13, "El RFC ingresado tiene más de 13 dígitos")
    .trim(),
});

const Addcompany = (props) => {
  const { cancel, editMode = false, dataToEdit } = props;

  const [sending, setSending] = useState(false);
  const [error, SetError] = useState(false);
  const [nameErrors, SetErrorsw] = useState("");

  // const [editMode, dataEditMode] = useState(false);

  let sesssionData = null;

  const dataAsigned = () => {
    const dataUse = {
      name: "",
      rso: "",
      emails: "",
      rfc: "",
    };

    if (editMode) {
      dataUse.name = dataToEdit?.name;
      dataUse.emails = dataToEdit?.emails;
      dataUse.isEdit = editMode;
    }

    return dataUse;
  };

  const dataSession = localStorage.getItem("tokenSession");
  const storedData = JSON.parse(dataSession);
  sesssionData = storedData;

  useEffect(() => {
    dataAsigned();
    const dataSession = localStorage.getItem("tokenSession");
    const storedData = JSON.parse(dataSession);
    sesssionData = storedData;
  }, []);

  const validationSchema = editMode
    ? schema
    : schema.concat(rsoSchema).concat(rfcSchema);

  const handleClick = () => {
    if (cancel) {
      cancel();
    }
  };
  const {
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: dataAsigned(),
  });

  const onSubmit = async (data) => {
    setSending(true);
    let result = null;
    if (!editMode) {
      try {
        result = await createConstrurama(data, sesssionData?.access_token);
      } catch (e) {
        setSending(false);
        SetError(true);
      }
    } else {
      try {
        result = await updateConstrurama(
          data,
          dataToEdit?.id,
          sesssionData?.access_token
        );
      } catch (e) {
        setSending(false);
        SetError(true);
      }
    }

    if (result?.status === 201 || result?.status === 200) {
      setSending(false);
      cancel();
    } else if (result?.status === 400) {
      setSending(false);
      SetError(true);

      SetErrorsw(result?.errors);
    }
    clearErrors();
  };

  return (
    <div>
      {!sending && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12"></div>
              <div className="col-md-12">
                <div className="input-block mb-3">
                  <label className="form-control-label">Nombre</label>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <input
                        className={`form-control  ${
                          errors?.name ? "error-input" : ""
                        }`}
                        type="text"
                        value={value}
                        onChange={onChange}
                        placeholder="Ingresa el nombre de la sucursal"
                        autoComplete="false"
                      />
                    )}
                  />
                  <small className="text-red-600">
                    {errors?.name?.message}
                  </small>
                </div>
              </div>
              {!editMode && (
                <>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="form-label">RSO ID </label>
                      <Controller
                        name="rso"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <input
                            className={`form-control  ${
                              errors?.rso ? "error-input" : ""
                            }`}
                            type="text"
                            value={value}
                            onChange={onChange}
                            placeholder="Ingresa el RSO ID"
                            autoComplete="false"
                          />
                        )}
                      />
                      <small className="text-red-600">
                        {errors?.rso?.message}
                      </small>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="mb-2">RFC de la sucursal</label>
                      <Controller
                        name="rfc"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <input
                            className={`form-control  ${
                              errors?.rfc ? "error-input" : ""
                            }`}
                            type="text"
                            value={value}
                            onChange={onChange}
                            placeholder="Ingresa el RFC de la sucursal"
                            autoComplete="false"
                          />
                        )}
                      />
                      <small className="text-red-600">
                        {errors?.rfc?.message}
                      </small>
                    </div>
                  </div>
                </>
              )}

              <div className="col-md-12">
                <div className="input-block mb-3">
                  <label className="form-label">
                    Emails (separados por comas)
                  </label>
                  <Controller
                    name="emails"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <textarea
                        className={`form-control  ${
                          errors?.emails ? "error-input" : ""
                        }`}
                        type="text"
                        value={value}
                        onChange={onChange}
                        placeholder="Ingresa los emails separados por comas."
                        autoComplete="false"
                      />
                    )}
                  />
                  <small className="text-red-600">
                    {errors?.emails?.message}
                  </small>
                </div>
              </div>
            </div>
          </div>
          {error && (
            <small className="text-red-600">
              Ocurrió un error al insertar los datos, intenta mas tarde.
            </small>
          )}
          <br></br>
          <small className="text-red-600">{error && nameErrors?.rfc}</small>
          <small className="text-red-600"> {error && nameErrors?.id}</small>

          <br></br>

          <div className="modal-footer">
            <button
              type="button"
              onClick={() => handleClick()}
              className="btn btn-orange-construrama cancel-btn me-2"
            >
              Cancelar
            </button>
            {!editMode && (
              <>
                <button
                  id="btn-send"
                  type="submit"
                  className="btn btn-blue-construrama"
                >
                  Agregar
                </button>
              </>
            )}
            {editMode && (
              <>
                <button
                  id="btn-send"
                  type="submit"
                  className="btn  btn-blue-construrama"
                >
                  Editar
                </button>
              </>
            )}
          </div>
        </form>
      )}

      {sending && (
        <>
          <div className="w-full h-[500px] flex justify-center items-center">
            <svg
              aria-hidden="true"
              className="w-[60px] h-[60px] text-gray-200 animate-spin dark:text-gray-600 fill-[#5A43EB]"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          </div>
        </>
      )}
    </div>
  );
};
Addcompany.propTypes = {
  editMode: PropTypes.bool.isRequired,
  dataToEdit: PropTypes.any,
  cancel: PropTypes.func, // Definir prop onClose como función
};

export default Addcompany;
