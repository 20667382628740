import React, { useEffect, useState } from "react";

import Header from "../layouts/Header";
import Select from "react-select";
import Sidebar from "../layouts/Sidebar";
import moment from "moment";
import {
  downloadFinishedCatalog,
  getAllFinishedCatalogs,
  updateStatusCatalogFinished,
} from "../assets/api/construrama/api_construrama";
import { Table } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Loader from "../_components/general/loader";
import { resetCatalog } from "../assets/api/admin/admin_api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const FinishedCatalogs = () => {
  const [menu, setMenu] = useState(false);
  // const [show, setShow] = useState(false);
  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);
  const [statusSelect, setStatusSelect] = useState(1);

  let history = useHistory();
  let sesssionData = null;

  const dataSession = localStorage.getItem("tokenSession");
  sesssionData = JSON.parse(dataSession);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const download = async (index) => {
    try {
      await downloadFinishedCatalog(sesssionData?.access_token, index);
    } catch (e) {
      console.log("ocurrio un error ", e);
    }
  };

  const getFinishedCatalogs = async () => {
    if (!sesssionData?.access_token) {
      console.error("Token de acceso no válido o indefinido");
      return;
    }

    setLoader(true);
    try {
      const result = await getAllFinishedCatalogs(
        sesssionData?.access_token,
        statusSelect
      );
      setData(result?.response !== "no_data" ? result : []);
    } catch (e) {
      console.log("Ocurrió un error ", e);
    }
    setLoader(false);
  };

  const seeCatalog = (id,status) => {
    history.push("/admin/see-catalog-finished", { isFinished: status, rso_id: id });
  };

  const changeFinished = async (id) => {
    setLoader(true);
    try {
      await updateStatusCatalogFinished(id, 0, sesssionData?.access_token);

      toast.success("Catálogo actualizado", {
        autoClose: 1200,
        hideProgressBar: true,
      });
      getFinishedCatalogs();
    } catch (e) {
      console.log(" hubo un error", e);
    }
    setLoader(false);
  };

  const resetCatalogFinished = async (id) => {
    setLoader(true);
    try {
      await resetCatalog(id, sesssionData?.access_token);
      toast.success("Catálogo actualizado", {
        autoClose: 1200,
        hideProgressBar: true,
      });
      getFinishedCatalogs();
    } catch (e) {
      console.log("hubo un error ", e);
    }
    setLoader(false);
  };

  const changeCatalogues = (status) => {
    setStatusSelect(status);
  };

  const columns = [
    {
      title: "RSO ID",
      dataIndex: "id",
    },
    {
      title: "Nombre",
      dataIndex: "name",
    },
    {
      title: "Estatus",
      dataIndex: "status",
      render: (text) => (
        <div>
          {text === 0 && (
            <span className="badge bg-danger-light ">
              <i className="fe fe-x me-1"></i>
              En proceso
            </span>
          )}
          {text === 1 && (
            <span className="badge  bg-success-light">
              <i className="fe fe-check me-1"></i>
              Finalizado
            </span>
          )}
        </div>
      ),
    },
    {
      title: "Finalizado",
      dataIndex: "updated_at",
      render: (update) => {
        return <p>{moment(update).format("DD/MM/YYYY")}</p>;
      },
    },

    {
      title: "Archivo",
      dataIndex: "id",
      width: 100,
      render: (index) => (
        <div className="w-[100px] flex items-center justify-between gap-1">
          <button
            onClick={() => download(index)}
            title="Descargar excel"
            className="bg-[#1C3661] text-white py-2.5 w-[50px] rounded-md ml-2 "
          >
            <i className="fe fe-file-text text-lg" />
          </button>
        </div>
      ),
    },
    {
      title: "Acción",
      dataIndex: "id",
      width: 200,
      render: (index, record) => (
        <div className="w-[200px] flex items-center justify-between gap-0.5">
          <button
            onClick={() => seeCatalog(index, record?.status)}
            title="Ver catálogo "
            className="bg-[#1C3661] text-white py-2.5 w-[50px] rounded-md ml-2 "
          >
            <i className="fe fe-search  text-lg" />
          </button>
          <button
            className={
              record.status === 1
                ? "bg-[#ff3500] text-white py-2.5 w-[50px] rounded-md ml-2"
                : "bg-[#797574]  text-white py-2.5 w-[50px] rounded-md ml-2 cursor-not-allowed"
            }
            disabled={record.status !== 1}
            onClick={() => changeFinished(index)}
            title="Volver a no finalizado"
          >
            <span>
              <i className="fe fe-arrow-left  text-lg" />
            </span>
          </button>

          <button
            onClick={() => resetCatalogFinished(index)}
            title="Eliminar productos"
            className="bg-[#ff3500] text-white py-2.5 w-[50px] rounded-md ml-2 "
          >
            <i className="fe fe-trash  text-lg" />
          </button>
        </div>
      ),
    },
  ];

  const options = [
    {
      value: "1",
      label: "Finalizado",
    },
    {
      value: "0",
      label: "En proceso",
    },
  ];

  useEffect(() => {
    getFinishedCatalogs();
  }, [statusSelect]);

  return (
    <>
      <ToastContainer />
      <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
        <Header onMenuClick={() => toggleMobileMenu()} />

        <Sidebar />

        <div className="page-wrapper customers">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="content-page-header">
                <h5>Catálogos</h5>
              </div>
            </div>
          </div>
          <div className=" p-[20px] flex items-center gap-[20px]">
            <p>Mostrar cátalogos </p>
            <Select
              className="border border-gray-300 rounded-lg focus:border-gray-300 z-50 w-[150px]"
              name="type"
              options={options}
              defaultValue={options[0]}
              onChange={(choice) => changeCatalogues(choice)}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: "#F9FAFB",
                  border: 0,
                  boxShadow: "none",
                  color: "#D1D5DB;",
                  fontSize: "14px",
                  borderRadius: "8px",

                  width: "100%",
                  padding: "2px",
                }),
              }}
            />
          </div>
          <div className="min-w-full">
            <div className=" h-auto flex flex-col gap-[20px] max-w-full p-[20px]">
              <section className="table-pim max-w-full  overflow-x-auto pb-[20px] ">
                <div className="card-body">
                  <Table
                    columns={columns}
                    dataSource={data?.map((item) => ({
                      ...item,
                      key: item.id,
                    }))}
                    pagination={false}
                    className="border border-slate-100 rounded-lg"
                    locale={{ emptyText: "Sin catálogos todavia" }}
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Loader show={loader}></Loader>
    </>
  );
};

export default FinishedCatalogs;
