/* eslint-disable */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { InvoiceLogo1 } from "../_components/Imagepath";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailrgx } from "../assets/constant";
import { authLogin } from "../assets/api/logins/api_logins";
import { useHistory } from "react-router-dom";

const schema = yup.object({
  email: yup
    .string()
    .matches(emailrgx, "Ingresa el email")
    .required("Ingresa el email")
    .trim(),
  password: yup
    .string()
    .min(6, "la contraseña debe ser de almenos 6 caracteres")
    .required("Ingresa la contraseña")
    .trim(),
});

const LoginAdmin = (props) => {
  const [eye, seteye] = useState(true);
  const [errorLogin, setErrorLogin] = useState();
  const [errorUnable, setErrorUnable] = useState();
  const history = useHistory();

  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const login = async (data) => {
    try {
      const result = await authLogin(data);
      if (result?.access_token) {
        localStorage.setItem("tokenSession", JSON.stringify(result));
        history.push("/admin/dashboard");
        // return <Redirect to="/identify" />;
      } else {
        setErrorLogin(result?.error);
      }
    } catch (e) {
      setErrorUnable("Hubo un error inesperado, intenta mas tarde");
    }
  };

  const onSubmit = (data) => {
    login(data);
    setErrorLogin("");
    clearErrors();
  };

  const onEyeClick = () => {
    seteye(!eye);
  };

  return (
    <>
      <div className="main-wrapper login-body">
        <div className="login-wrapper">
          <div className="container">
            <div className="loginbox">
              <div className="login-right">
                <div className="login-right-wrap">
                  <p className="account-subtitle">Acceso al Catalogo PIM</p>
                  <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="input-block mb-3 input_text">
                        <label className="form-control-label">Email</label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <input
                              className={`form-control  ${
                                errors?.email ? "error-input" : ""
                              }`}
                              type="text"
                              onChange={onChange}
                              autoComplete="false"
                            />
                          )}
                        />

                        <small>{errors?.email?.message}</small>
                      </div>
                      <div className="input-block mb-3 input_text login-eye">
                        <label className="form-control-label">Contraseña</label>
                        <Controller
                          name="password"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <div className="pass-group">
                              <input
                                type={eye ? "password" : "text"}
                                className={`form-control  ${
                                  errors?.password ? "error-input" : ""
                                }`}
                                onChange={onChange}
                                autoComplete="false"
                              />
                              <span
                                onClick={onEyeClick}
                                className={`fas toggle-password ${
                                  eye ? "fa-eye-slash" : "fa-eye"
                                }`}
                              />
                            </div>
                          )}
                        />

                        <small>{errors?.password?.message}</small>
                      </div>
                      <small>{errorLogin}</small>
                      <small>{errorUnable}</small>
                      <div className="input-block mb-3"></div>
                      <button
                        className="btn btn-lg btn-block w-100 btn-primary w-100"
                        type="submit"
                      >
                        Enviar
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginAdmin;
